import Emitter from "./../shared/Emitter";
import FiltersModel from './FiltersModel';
import FiltersViewService from './FiltersViewService';


const filtersModel = new FiltersModel();


export default class FiltersView extends Emitter {

  #filtersNode
  #filtersContentNode
  #filtersCloseNode

  constructor() {
    super();
    this.#filtersNode = document.getElementById('filters');
    this.#filtersContentNode = document.getElementById('filters-content');
    this.#filtersCloseNode = document.getElementById('filters-toggle');

    filtersModel.on('filtersChange', filters => this.#rerenderFilters(filters));
    this.#filtersCloseNode.addEventListener('click', () => {
      this.#filtersNode.classList.toggle('is-active');
    });
  }

  #rerenderFilters(filters) {
    this.#filtersContentNode.innerHTML = '';

    Object.keys(filters).forEach(filterType => { // проходимось по кожному типу фільтів 
      const filtersBlockElement = FiltersViewService.createFilterBlock({filterType}); // створюємо розмітку блоку конкретного типу
      
      filters[filterType].forEach(filter => { // проходимось фільтрах конкретного типу
        const filterElement = FiltersViewService.createFilter(filter); // створюємо розмітку фільтрів конкретного типу

        filterElement.addEventListener('click', () => this.emit('toggleFilter', filter));
        filtersBlockElement.appendChild(filterElement); // інклюдимо фільтр у батьківський блок
      });

      this.#filtersContentNode.appendChild(filtersBlockElement); // інклюдимо блок у загальний контейнер фільтрів
    });
  }


}