import mitt from 'mitt';

export default class Emitter {
  constructor() {
		this.emiter = mitt();
	}

	emit(event, payload) {
		this.emiter.emit(event, payload);
	}

	on(event, cb) {
		this.emiter.on(event, cb);
	}

	off(event, cb) {
		this.emiter.off(event, cb);
	}

}