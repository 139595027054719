import Emitter from "../shared/Emitter";
import BuildingsModel from "../BuildingsModel";


const buildingsModel = new BuildingsModel();

export default class FiltersModel extends Emitter {

  #filters;

  constructor() {
    if (typeof FiltersModel.instance === 'object') return FiltersModel.instance;
    super();

    this.#filters = {};

    this.#init();
  
    FiltersModel.instance = this;
  }

  async #init() {
    // Types
    const typesFromServer = await buildingsModel.getTypes();
    const typeFilters = this.#generateFilters(typesFromServer, 'type');
    this.#filters['type'] = typeFilters;

    // Statuses
    const statusesFromServer = await buildingsModel.getStatuses();
    const statusesFilters = this.#generateFilters(statusesFromServer, 'status');
    this.#filters['status'] = statusesFilters;

     // Statuses
     const statesFromServer = await buildingsModel.getStates();
     const statesFilters = this.#generateFilters(statesFromServer, 'state');
     this.#filters['state'] = statesFilters;

    this.emit('filtersChange', this.#filters);
  }

  #generateFilters(filtersFromServer, type) {
    return filtersFromServer.map(filter => {
      return {
        name: filter.name,
        id: filter._id,
        type: type,
        selected: true
      };
    })
     ;
  }

  getFilters() {
    return this.#filters;
  }

  getActiveFilters() {
    let activeFilters = {...this.#filters};

    Object.keys(this.#filters).forEach(filterType => {
      activeFilters[filterType] = activeFilters[filterType].filter(filter => filter.selected);
    });

    return activeFilters;
  }

  toggleFilter({type, id}) {
   const updatedFilter = this.#filters[type].find(filter => filter.id === id);
   updatedFilter.selected = !updatedFilter.selected;

   this.emit('filtersChange', this.#filters);
  }

  
}