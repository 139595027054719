import Emitter from './shared/Emitter';
import BuildingService from "./services/buildings.service";


export default class BuildingsModel extends Emitter {

  #buildings;

  #types;
  #statuses;
  #states;

  #activeBuildings;

  constructor() {
    if (typeof BuildingsModel.instance === 'object') return BuildingsModel.instance;
    super();

    this.#buildings = [];
    this.#activeBuildings = [];

    this.#getBuildingsFromServer();

    BuildingsModel.instance = this;
  }

  async #getBuildingsFromServer() {
    this.#types = await this.#getTypes();
    this.#statuses = await this.#getStatuses();
    this.#states = await this.#getStates();
    const buildings = await this.#getBuildings();

    buildings.forEach(building => {
      // Set extra types
      const currentType = this.#types.find(type => type._id === building.type._id);
      building.type.key = currentType.key;
      building.type.type = currentType.type;

      // Set extra status
      const currentStatus = this.#statuses.find(status => status._id === building.status._id);
      building.status.key = currentStatus.key;

      // Set extra states
      const currentStates = this.#states.find(state => state._id === building.state._id);
      building.state.key = currentStates.key;
    });

    this.#buildings = buildings;
    this.#activeBuildings = [...this.#buildings];

    this.emit('updateActiveBuildings', this.#activeBuildings);
  }

  async #getBuildings() {
    const buildings = await BuildingService.getBuildings();
    // this.emit('update', this.store);
    return buildings;
  }

  async #getStates() {
    const states = await BuildingService.getStates();
    // this.emit('update', this.store);
    return states;
  }

  async #getStatuses() {
    const statuses = await BuildingService.getStatuses();
    // this.emit('update', this.store);
    return statuses;
  }

  async #getTypes() {
    const types = await BuildingService.getTypes();
    return types;
  }

  getBuilding(buildingID) {
    const building = this.#buildings.find(building => building._id === buildingID);
    if (!building) throw new Error(`Can't find a building with id: ${buildingID}`);
    
    return building;
  }

  async getStatuses() {
    if (!this.#statuses) this.#statuses = await this.#getStatuses();
    return this.#statuses;
   }

  async getTypes() {
  if (!this.#types) this.#types = await this.#getTypes();  
  return this.#types;
  }

  async getStates() {
    if (!this.#states) this.#states = await this.#getStates();
    return this.#states;
   }



  filter(activeFilters) {
    let activeBuildings = [...this.#buildings];

    Object.keys(activeFilters).forEach(key => {
      activeFilters[key] = activeFilters[key].map(activeFilter => activeFilter.id);
    });

    Object.keys(activeFilters).forEach(key => {
      activeBuildings = activeBuildings.filter(building => activeFilters[key].includes(building[key]._id));
    });

    this.#activeBuildings = [...activeBuildings];
    this.emit('updateActiveBuildings', this.#activeBuildings);
  }
}