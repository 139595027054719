import BuildingsModel from './BuildingsModel';
import Swiper, {Navigation} from 'swiper';
import { IMAGE_SRC, STATE_ICONS, STATUS_ICONS } from './shared/data';
import 'swiper/css';


const buildingsModel = new BuildingsModel();
Swiper.use([Navigation]);


export default class BuildingView {
  constructor() {
    this.infoNode = document.querySelector('#info');
    this.closeNode = this.infoNode.querySelector('#info-close');

    this.monumentName = this.infoNode.querySelector('#monument-name');
    this.monumentGallery = this.infoNode.querySelector('#monument-gallery');
    this.munumentGalleryImages = this.monumentGallery.querySelector('#monument-gallery-images');

    this.munumentStatus = this.infoNode.querySelector('#monument-status');
    this.munumentStatusIcon = this.infoNode.querySelector('#monument-status-icon');
    this.munumentStatusText = this.infoNode.querySelector('#monument-status-text');

    this.munumentState = this.infoNode.querySelector('#monument-state');
    this.munumentStateIcon = this.infoNode.querySelector('#monument-state-icon');
    this.munumentStateText = this.infoNode.querySelector('#monument-state-text');

    this.monumentDescription = this.infoNode.querySelector('#monument-description');

    this.closeNode.addEventListener('click', () => this.close());
  }

  setData(buildingID) {
    this.#reset();
    const data = buildingsModel.getBuilding(buildingID);

    // Add images
    if (data.images) {
      data.images.forEach(image => {
        const imageNode = this.#createImg(image);
        this.munumentGalleryImages.append(imageNode);
  
        new Swiper(this.monumentGallery, {
          navigation: {
            prevEl: "#monument-gallery-prev",
            nextEl: "#monument-gallery-next"
          },
        });
      });
    } else {
      this.monumentGallery.classList.add('is-empty');
    }

    this.monumentName.textContent = data.name;

    this.munumentState.dataset.state = data.state.key;
    this.munumentStateIcon.insertAdjacentHTML('beforeend', STATE_ICONS[data.state.key] || STATE_ICONS[10]);
    this.munumentStateText.textContent = data.state.display;

    this.munumentStatus.dataset.status = data.status.key;
    this.munumentStatusIcon.insertAdjacentHTML('beforeend', STATUS_ICONS[data.status.key] || STATUS_ICONS[10]);
    this.munumentStatusText.textContent = data.status.display;

    this.monumentDescription.innerHTML = data.description;
  }

  #createImg(image) {
    // console.log(image);
    // Create wrapper
    const imgWrapper = document.createElement('div');
    imgWrapper.classList.add('monument-gallery__slide', 'swiper-slide');

    // Create image
    const imgNode = document.createElement('img');
    let imgSrc = IMAGE_SRC + `&src=${image.path}`;
    imgNode.setAttribute('src', imgSrc);
    imgNode.setAttribute('title', image.meta.title);

    // Create description
    const descriptionNode = document.createElement('span');
    descriptionNode.classList.add('monument-gallery__slide-description');
    descriptionNode.innerHTML = image.meta.description ? image.meta.description : image.meta.title;
    // descriptionNode.addEventListener('click', navigator.clipboard.writeText(descriptionNode.textContent));

    // Append into wrapper
    imgWrapper.append(imgNode);
    imgWrapper.append(descriptionNode);

    return imgWrapper;
  }

  #reset() {
    this.monumentName.textContent = "";
    this.munumentGalleryImages.innerHTML = "";
    this.monumentGallery.classList.remove('is-empty');

    this.munumentState.dataset.state = "";
    this.munumentStateIcon.innerHTML = "";
    this.munumentStateText.textContent = "";
    
    this.munumentStatus.dataset.status = "";
    this.munumentStatusIcon.innerHTML = "";
    this.munumentStatusText.textContent = "";

    this.monumentDescription.innerHTML = "";
  }

  open() {
    this.infoNode.classList.add('is-active');
  }

  close() {
    this.infoNode.classList.remove('is-active');
    this.#reset();
  }
}
