import { IMAGE_SRC } from '../shared/data';

export default class BuildingMarkerService {

  static generateMarkup(options, clickcb) {
    const markerNode = document.createElement('div');
    markerNode.className = `marker marker-${options.type.type}`;

    // hover не через батьківський елемент, щоб уникнути залипання анімації
    const hoverNode = document.createElement('div');
    hoverNode.classList.add('marker__trigger');

    const tooltipNode = document.createElement('div');
    tooltipNode.classList.add('marker__tooltip');
    tooltipNode.textContent = options.name;

    markerNode.append(hoverNode, tooltipNode);

    hoverNode.addEventListener('mouseout', () => tooltipNode.classList.remove('is-active'));
    hoverNode.addEventListener('mouseover', () => {
      // прелоад зображення, щоб скоротити час відображення у сайдбарі при виборі
      options.images?.forEach(image => new Image().src = IMAGE_SRC + `&src=${image.path}`);
      tooltipNode.classList.add('is-active');
    });

    if (clickcb)
      markerNode.addEventListener('click', () => clickcb({buildingID: options._id}));

    return markerNode;
  }

}