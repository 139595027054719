import '../styles/index.scss';
import MapController from './map/MapController';
import BuildingView from './BuildingView';
import FiltersController from './filters/FiltersController';


window.addEventListener('load', () => document.getElementById('preload').classList.add('is-hidden'));

const mapController = new MapController();
const buildingView = new BuildingView();

mapController.on('clickOnBuilding', (buildingID) => {
  buildingView.setData(buildingID);
  buildingView.open();
});

new FiltersController();
