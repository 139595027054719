import {API_TOKEN, API_URL} from '../shared/consts';


export default class BuildingService {
  constructor(){}

  static async getBuildings() {
    const response = await fetch(`${API_URL}/collections/get/Buildings?token=${API_TOKEN}`, {
      method: 'post',
    });
    const responseJson = await response.json();

    return responseJson.entries;
  }

  static async getStates() {
    const response = await fetch(`${API_URL}/collections/get/State?token=${API_TOKEN}`);
    const responseJson = await response.json();
    return responseJson.entries;
  }

  static async getStatuses() {
    const response = await fetch(`${API_URL}/collections/get/Status?token=${API_TOKEN}`);
    const responseJson = await response.json();
    return responseJson.entries;
  }

  static async getTypes() {
    const response = await fetch(`${API_URL}/collections/get/Types?token=${API_TOKEN}`);
    const responseJson = await response.json();
    return responseJson.entries;
  }
}