const TITLES = {
  type: "Тип пам'ятки",
  status: "Пам'яткоохоронний статус",
  state: "Стан збереження"
};

const FILTERS_BLOCK_TEMPLATE = document.getElementById('filters-block-template');
const FILTER_TEMPLATE = document.getElementById('filter-template');

export default class FiltersViewService {
  static createFilterBlock({filterType}) {
    const blockElement = FILTERS_BLOCK_TEMPLATE.cloneNode(true);

    blockElement.querySelector('.filters-block__title').textContent = TITLES[filterType];

    return blockElement;
  }

  static createFilter(filter) {
    const filterElement = FILTER_TEMPLATE.cloneNode(true);
    
    filterElement.textContent = filter.name;
    if (filter.selected) filterElement.classList.add('is-checked');

    return filterElement;
  }
}