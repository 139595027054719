import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
const vinnytsiaGeojson = require('@data/Vinnytsia.json'); 

export default class MapboxProvider {
  constructor() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoicm9pa3l1cmlpIiwiYSI6ImNra29rNTYyZzAwa2kydnBqZXNuajhsODYifQ.QGzwmeppPKvolj3jDItG2w';

    this.defaultOptions = {
      container: 'map', 
      style: 'mapbox://styles/roikyurii/ckyhn6rpw847v14nu3bxddrj2',
      center: [-74.5, 40], 
      zoom: 9,
      bounds: [
        [27.116132,48.054213], // Southwest coordinates
        [30.076868, 49.931041] // Northeast coordinates
      ]
    };
  }

  createMap(customOptions) {
    this.map = new mapboxgl.Map({...this.defaultOptions, ...customOptions});
    return this.map;
  }

  addVinnytsiaRegion(cb) {
    this.map.on('load', () => {
      this.map.addSource('route', {
        type: 'geojson',
        data: vinnytsiaGeojson
      });

      this.map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#A6A69D',
          'line-width': 2
        }
      });

      this.map.setLayoutProperty('country-label', 'text-field', [
        'get',
        `name_de`
      ]);

      if(cb) cb();
    });
  }

  createMarker(markerView, coordinates) {
    return new mapboxgl.Marker(markerView).setLngLat([coordinates.lng, coordinates.lat]);
  }

  addMarkerOnMap(marker) {
    marker.addTo(this.map);
  }

}
