import Emitter from '../shared/Emitter';
import MapView from './MapView';
// import UrlService from '../services/url.service';


export default class MapController extends Emitter{

  #mapView;

  constructor() {
    super();

    this.#mapView = new MapView();

    this.#mapView.on('clickOnBuilding', (buildingID) => {
      this.emit('clickOnBuilding', buildingID);
      // UrlService.setUrlParam('building_id', buildingID);
    });
  }


}