import Emitter from "../shared/Emitter";
import BuildingsModel from '../BuildingsModel';
import FiltersView from './FiltersView';
import FiltersModel from './FiltersModel';

const buildingsModel = new BuildingsModel();
const filtersModel = new FiltersModel();

// Array.prototype.removeElement = function(element) {
//   const newArr = this.filter(arrayElement => arrayElement !== element);
//   console.log(newArr);
// };

export default class FiltersController extends Emitter {
  constructor() {
    super();

    this.filtersView = new FiltersView();
    this.filtersView.on('toggleFilter', filter => {
      filtersModel.toggleFilter(filter);
      
      const activeFilters = filtersModel.getActiveFilters();
      buildingsModel.filter(activeFilters);
    });

    this.init();
  }

  async init() {
   
  }

  _activateFilter(name) {
    this.filters[name] = true;
    this.emit('changeFilter', this.filters);
  }

  _deactivateFiltername(name) {
    this.filters[name] = false;
    this.emit('changeFilter', this.filters);
  }

  _toggleFilter(options) {
    this.emit('changeFilter', options);
  }
}
