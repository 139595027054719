import MapboxProvider from "./MapboxProvider";
import BuildingsModel from "../BuildingsModel";
import BuildingMarkerService from './BuildingMarkerService';
import Emitter from "../shared/Emitter";


const buildingsModel = new BuildingsModel();


export default class Map extends Emitter{

  #mapProvider;

  #markers;

  constructor() {
    super();
    this.#markers = [];

    this.#mapProvider = new MapboxProvider;
    this.#mapProvider.createMap();
    this.#mapProvider.addVinnytsiaRegion();

    buildingsModel.on('updateActiveBuildings', this.#buildingsUpdateHandler.bind(this));
  }

  #buildingClickHandler({buildingID}) {
    this.emit('clickOnBuilding', buildingID);
  }
  
  #buildingsUpdateHandler(buildings) {
    this.#markers.forEach(marker => marker.remove());

    this.#markers = buildings.map(building => {
      const buildingMarkerMarkup = BuildingMarkerService.generateMarkup(building, this.#buildingClickHandler.bind(this));
      return this.#mapProvider.createMarker(buildingMarkerMarkup, building.coordinates);
    });

    this.#markers.forEach(marker => this.#mapProvider.addMarkerOnMap(marker));
  }
}

